import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestOptions, Headers } from '@angular/http';
import { User } from '../../../models/user';
import { Observable } from '../../../node_modules/rxjs';

// import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private domain = 'http://www.marketpro.mk';
  // private domain = 'http://localhost:8080';
  authToken;
  user;
  options;
  isLogged = false;

  constructor(private http: HttpClient) { }

  // createAuthenticationHeaders() {
  //   this.loadToken();
  //   this.options = new RequestOptions({
  //     headers: new Headers({
  //       'Content-Type': 'application/json',
  //       'authorization': this.authToken
  //     })
  //   });
  // }

  // loadToken() {
  //   const token = localStorage.getItem('token');
  //   this.authToken = token;
  // }

  registerUser(user: User): Observable<User> {
    return this.http.post<User>(this.domain + '/authentication/register', user);
  }

  checkUsername(username) {
    return this.http.get(this.domain + '/authentication/checkUsername/' + username);
  }

  checkEmail(email) {
    return this.http.get(this.domain + '/authentication/checkEmail/' + email);
  }

  login(user) {
    return this.http.post(this.domain + '/authentication/login', user);
  }

  logout() {
    this.authToken = null;
    this.user = null;
    localStorage.clear();
  }

  // On login save token and username in localstorage
  storeUserData(token, user) {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.authToken = token;
    this.user = user;
  }

  // getProfile() {
  //   this.createAuthenticationHeaders();
  //   return this.http.get(this.domain + '/authentication/profile', this.options);
  // }

  // loggedIn() {
  //   return this.jwtHelper.isTokenExpired(this.authToken);
  // }

  isLoggedIn() {
    if (localStorage.getItem('token') == null) {
      this.isLogged = false;
      return this.isLogged;
    } else {
      return true;
    }
  }
}
