import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private http: HttpClient) { }

  selectedFile = null;

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
  }

  onUpload() {
  }

  ngOnInit() {
  }

}
