import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private flashMessagesService: FlashMessagesService
  ) { }

  @ViewChild('sidebar') sidebar;
  @ViewChild('content') content;
  @ViewChild('background') background;
  // @ViewChild('body') body;

  addClassNavbar() {
    this.sidebar.nativeElement.classList.add('active');
    // this.content.nativeElement.classList.add('overlay', 'active');
    this.background.nativeElement.classList.add('active');
    // this.body.classList.add('overflow, hidden');
  }
  removeClassNavbar() {
    this.sidebar.nativeElement.classList.remove('active');
    // this.content.nativeElement.classList.remove('overlay', 'active');
    this.background.nativeElement.classList.remove('active');
  }

  onLogoutClick() {
    this.authService.logout();
    this.flashMessagesService.show('You are logged out', {
      cssClass: 'container alert-info'
    });
    this.router.navigate(['/']);
  }

  isLogged(log) {
    log = this.authService.isLoggedIn();
    if (log === true) {
      return true;
    } else {
      return false;
    }
  }

  // toggleClassSidebar() {
  //   // this.status = !this.status;
  //   if (this.status === false) {
  //     this.sidebarCategory.nativeElement.classList.add('d-none');
  //   } else {
  //     this.sidebarCategory.nativeElement.classList.add('d-flex');
  //     this.sidebarCategory.nativeElement.classList.remove('d-none');
  //   }
  // }

  ngOnInit() { }
}

// toogleClass(event) {
//   event.target.classList.add('cat_active');
// }
