import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headers = {};
        const token = window.localStorage['token'];
        if (token) {
            headers[token] = token;
        }
        request = request.clone({
            setHeaders: headers
        });
        return next.handle(request);
    }
}
