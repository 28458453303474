import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  message;
  messageClass;
  processing = false;
  emailValid;
  emailMessage;
  usernameValid;
  usernameMessage;

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService) {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$'),
        Validators.minLength(5),
        Validators.maxLength(30)
      ])],
      username: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]{3,15}$'),
        Validators.minLength(3),
        Validators.maxLength(15)
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}$'),
        Validators.minLength(8),
        Validators.maxLength(35)
      ])],
      confirm: ['', Validators.required]
    }, {
        validator: this.matchingPassword('password', 'confirm')
      });
  }

  disableForm() {
    this.registerForm.controls['email'].disable();
    this.registerForm.controls['username'].disable();
    this.registerForm.controls['password'].disable();
    this.registerForm.controls['confirm'].disable();
  }

  enableForm() {
    this.registerForm.controls['email'].enable();
    this.registerForm.controls['username'].enable();
    this.registerForm.controls['password'].enable();
    this.registerForm.controls['confirm'].enable();
  }

  // Funciton to ensure passwords match
  matchingPassword(password, confirm) {
    return (group: FormGroup) => {
      // Check if both fields are the same
      if (group.controls[password].value === group.controls[confirm].value) {
        return null; // Return as a match
      } else {
        return { 'matchingPassword': true }; // Return as error: do not match
      }
    };
  }

  onSubmit() {
    this.processing = true;
    this.disableForm();
    const user = {
      email: this.registerForm.get('email').value,
      username: this.registerForm.get('username').value,
      password: this.registerForm.get('password').value
    };
    this.authService.registerUser(user).subscribe((res: any) => {
      if (!res.success) {
        this.messageClass = 'alert alert-danger';
        this.message = res.message;
        this.processing = false;
        this.enableForm();
      } else {
        this.messageClass = 'alert alert-success';
        this.message = res.message;
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
      }
    });
  }

  checkEmail() {
    this.authService.checkEmail(this.registerForm.get('email').value).subscribe((res: any) => {
      if (!res.success) {
        this.emailValid = false;
        this.emailMessage = res.message;
      } else {
        this.emailValid = true;
        this.emailMessage = res.message;
      }
    });
  }

  checkUsername() {
    this.authService.checkUsername(this.registerForm.get('username').value).subscribe((res: any) => {
      if (!res.success) {
        this.usernameValid = false;
        this.usernameMessage = res.message;
      } else {
        this.usernameValid = true;
        this.usernameMessage = res.message;
      }
    });
  }

  ngOnInit() {
    this.createForm();
  }
}
